@import "file-icons-js/css/style.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "antd/dist/antd.css";
@import "./design-system/styles/animations.css";
@import "./design-system/styles/vars.css";
@import "./design-system/components/components.css";

@layer base {
    h1 {
        @apply text-3xl !important;
    }
    h2 {
        @apply text-2xl !important;
    }
    h3 {
        @apply text-xl !important;
    }
    svg {
        @apply align-baseline !important;
    }

    /* Override Ant design normalizer */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: inherit !important;
        margin: 0 !important;
    }

    blockquote,
    dd,
    dl,
    figure,
    hr {
        @apply border border-grey-extra-light;
    }
    hr,
    p,
    pre {
        margin: 0 !important;
    }

    fieldset,
    ol,
    ul {
        margin: 0 !important;
        padding: 0 !important;
    }

    a {
        color: inherit;
        transition: none;
        text-decoration: inherit !important;
    }
    /* End override */
    /* Scroll bar override */
    ::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
    }
    ::-webkit-scrollbar-track {
        @apply bg-white;
    }
    ::-webkit-scrollbar-thumb {
        @apply bg-gray-200;
    }
    ::-webkit-scrollbar-thumb:hover {
        @apply bg-gray-300;
    }
    * {
        scrollbar-width: thin;
        scrollbar-color: rgba(229, 231, 235) white;
    }
}

@layer components {
    .link {
        @apply cursor-pointer text-sky-500 transition duration-300 hover:text-sky-400 hover:underline !important;
    }
    .btn {
        @apply mx-auto block cursor-pointer rounded-md px-4 py-2 text-base font-bold uppercase shadow-md duration-150 hover:text-accent !important;
        width: fit-content !important;
    }
    .btn-cta {
        @apply btn bg-accent-gradiant text-white hover:bg-success-gradiant hover:text-white !important;
    }
    .btn-outlined {
        @apply btn bg-white text-primary !important;
    }
    .btn-primary {
        @apply btn bg-primary text-white hover:bg-success-gradiant hover:text-white !important;
    }
    .custom-icon {
        @apply inline-block text-center font-normal !important;
        vertical-align: -0.125em !important;
    }
}

@supports (scrollbar-gutter: stable) {
    html body {
        overflow: auto !important;
    }
}

/* Prevent pie chart tooltip from flickering on hover */
.pie-chart svg > g > g.google-visualization-tooltip {
    pointer-events: none;
}
/* Prevent pie chart legend stroke from flickering on hover */
.pie-chart svg > g > g:nth-child(even) {
    pointer-events: none;
}
